/** @jsx jsx */
import React from 'react'
import { jsx, Box, Link as TLink } from "theme-ui"
import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title"
import getIcons from "../../../icons/icon-list"

const iconList = getIcons()

const ProjectsWidget = ({projects}) => {

  return (
    <>
      <Title text="Projects" />
      <ul>
        { projects.map((project) => {
          // Get the correct icon object from iconList by matching icon name
           const [myIcon] = iconList.filter(myIcon => myIcon.name === project.icon)

          return(
            <li key={project.title}>
              {/* Display icon before listing */}
              <TLink href={project.slug}>
                <Box sx={{display: 'inline', p:2}}>
                  <img src={myIcon.icon} alt="project-logo" sx={{ height: ['18px', '24px']}}/>
                </Box>
                <Box sx={{display: 'inline', p:2}}>
                  {project.title}
                </Box>
              </TLink>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default ProjectsWidget
