import icon1 from "./project-1.svg"
import icon2 from "./project-2.svg"
import icon3 from "./project-3.svg"

const iconList = () => [
  {name: "icon1", icon: icon1},
  {name: "icon2", icon: icon2},
  {name: "icon3", icon: icon3},
]

export default iconList
