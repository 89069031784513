import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
    </Text>
    <p>{`I work on computational law, smart contracts, and digital assets. I founded `}<a parentName="p" {...{
        "href": "https://www.clause.io"
      }}>{`Clause`}</a>{` (acq. by `}<a parentName="p" {...{
        "href": "www.docusign.com"
      }}>{`DocuSign`}</a>{`) and the Linux Foundation `}<a parentName="p" {...{
        "href": "https://www.accordproject.org"
      }}>{`Accord Project`}</a>{`. I sit on the `}<a parentName="p" {...{
        "href": "https://lawtechuk.io/ukjt"
      }}>{`UK Jurisdiction Taskforce`}</a>{`, work at and advise the `}<a parentName="p" {...{
        "href": "https://www.lawcom.gov.uk/"
      }}>{`Law Commission of England and Wales`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://www.scl.org/"
      }}>{`Society for Computers and Law`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://law.stanford.edu/directory/peter-hunn/"
      }}>{`Stanford CodeX`}</a>{`. My latest book, `}<a parentName="p" {...{
        "href": "https://global.oup.com/academic/product/smart-legal-contracts-9780192858467"
      }}>{`Smart Legal Contracts`}</a>{`, was published by Oxford University Press. `}<p>{` This site contains thoughts, resources, and updates on these projects and interests.`}</p></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      